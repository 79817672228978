<template>
  <div class="Contacto">
    <section class="relative top-layer">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="pt64 pb64 bg-secondary relative overflow-hidden">
              <div class="col-md-5 col-md-push-1 col-sm-6 col-sm-push-1">
                <h4 class="uppercase">Agenda tu cita o contáctanos</h4>
                <p>Para terapia emocional online (nacional e internacional)</p>
                <ul>
                  <li>Teléfonos: <strong>(614) 133 7988</strong> y <strong>(55) 1356 8812</strong></li>
                  <li>Correo electrónico de Laura Portillo: <strong>lauraportillo@bioemocioninteligente.com</strong></li>
                  <li>Correo electrónico de Marcos Sanchez: <strong>marcossanchez@bioemocioninteligente.com</strong></li>
                </ul>
                <h4 class="uppercase">Horario de atención</h4>
                <ul>
                  <li><strong>Laura Portillo</strong></li>
                  <li>Lunes - Viernes (Hora de Chihuahua)</li>
                  <li>3pm - 4:30pm</li>
                  <li>5pm - 6:30pm</li>
                  <li>7pm - 8:30pm</li>
                </ul>

                <ul>
                  <li><strong>Marcos Sánchez</strong></li>
                  <li>Lunes - Viernes (Hora de CDMX)</li>
                  <li>6pm - 7:30pm</li>
                  <li>8pm - 9:30pm</li>
                </ul>
              </div>

              <div
                class="col-sm-6 height-100 bg-dark right"
                style="background-color: #963d5a"
              >
                <div class="pt64 p32 pb-xs-64">
                  <h4
                    v-if="!showError"
                    class="uppercase"
                  >
                    Déjanos tu mensaje
                  </h4>
                  <div
                    v-else
                    style="
                      background: white;
                      color: #333 !important;
                      padding: 16px 12px;
                      margin-bottom: 24px;
                    "
                  >
                    Por favor llena todos los campos
                  </div>
                  <div v-if="!cargando">
                    <select
                      v-model="contacto.terapeuta"
                      name="terapeuta"
                      class="transparent"
                      style="color:white!important;text-transform:none!important"
                    >
                      <option
                        value="cita"
                        selected
                        disabled
                      >¿Con quién deseas tener tu cita?</option>
                      <option value="Laura Portillo">Laura Portillo</option>
                      <option value="Marcos Sánchez">Marcos Sánchez</option>
                    </select>
                    <input
                      v-model="contacto.nombre"
                      type="text"
                      name="name"
                      placeholder="Tu nombre"
                      class="transparent validate-required"
                    />
                    <input
                      v-model="contacto.telefono"
                      type="tel"
                      name="telefono"
                      v-mask="'X#############'"
                      placeholder="Teléfono"
                      class="transparent validate-required"
                    />
                    <input
                      v-model="contacto.correo"
                      type="email"
                      name="email"
                      placeholder="Correo electrónico"
                      class="transparent validate-required validate-email"
                    />
                    <textarea
                      v-model="contacto.mensaje"
                      name="message"
                      placeholder="Tu mensaje"
                      rows="3"
                      class="transparent"
                    ></textarea>
                    <input
                      @click="_sendFormulario"
                      class="mb0 btn btn-white btn-filled"
                      type="submit"
                      value="Enviar mensaje"
                    />
                  </div>
                  <div v-else>
                    <div class="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <p>Estamos enviando tu mensaje</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /><br /><br /><br /><br />
    </section>
    <section
      class="pb0"
      data-sr="enter bottom move 20px over .5s and scale 0% and wait 0s"
    >
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h2 class="uppercase mb48">Recuerda</h2>
            <p>
              Te atenderemos a la brevedad posible, sin embargo si consideras
              que es una emergencia te invitamos a que nos contactes en los
              telefonos que listamos arriba.
            </p>
            <br /><br /><br /><br />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
export default {
  name: "Contacto",
  mixins: [forms],
  data() {
    return {
      contacto: {
        nombre: null,
        telefono: null,
        correo: null,
        mensaje: null,
        terapeuta: "cita",
      },
      errors: {
        nombre: null,
        telefono: null,
        correo: null,
        mensaje: null,
        terapeuta: null,
      },
      showError: false,
    };
  },
  methods: {
    _sendFormulario() {
      if (
        this._validateAll("contacto") &&
        this.contacto.terapeuta &&
        this.contacto.terapeuta != "cita"
      ) {
        this.showError = false;
        this._saveAll(
          "contacto",
          { contacto: this.contacto },
          "msg",
          "_doRedirect"
        );
      } else {
        this.showError = true;
      }
    },
    _doRedirect(res) {
      this.$router.push("/gracias");
    },
  },
};
</script>
<style lang="css">
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>